import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Stack,
  Container,
  Heading,
  extendTheme,
  Fade,
} from "@chakra-ui/react"
import HomeLink from "./components/HomeLink"

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'gray.100',
        color: 'black',
      },
    },
  },
  colors: {
    brand: {
      50: "83E9AF",
      100: "#7EE1A9",
      200: "#79D7A1",
      300: "#65B687",
      500: "#559A73",
      700: "#487F5F",
      900: "#345B45"
    }
  }
})

export const App = () => (
  <ChakraProvider theme={theme} >
    <Fade in>
      <Container
        maxW={'6xl'}
        mt={{ base: 0, md: 24 }}
        p={0}
      >
        <Box
          px={12}
          py={20}
          bg='#65B687'
          bgGradient='linear(to-r, #65B687, #549A72)'
          color='white'
          borderRadius={{ base: 0, md: 4 }}
          mb={2}
        >
          <Heading
            fontWeight='600'
            size={{ base: 'xl', md: '2xl' }}
            mb={2}
          >
            brc plugins home page
          </Heading>
          <Text>
            Welcome to brc plugins home page! Here you can find all the useful links for our products.
          </Text>
        </Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={2}
          mb={2}
        >
          <HomeLink
            title='Docs'
            desc='Visit our docs for all of plugins.'
            url='https://docs.brcdev.net/'
          ></HomeLink>
          <HomeLink
            title='Feedback'
            desc='Vote on which features should be added first.'
            url='https://feedback.brcdev.net/'
          ></HomeLink>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={2}
        >
          <HomeLink
            title='Discord'
            desc='Join our Discord server to get support.'
            url='https://discord.brcdev.net'
          ></HomeLink>
          <HomeLink
            title='Purchase'
            desc='Buy our plugins available at SpigotMC marketplace.'
            url='https://www.spigotmc.org/resources/authors/brc.17348/'
          ></HomeLink>
        </Stack>

      </Container>
    </Fade>
  </ChakraProvider>
)
