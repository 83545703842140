import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text
} from '@chakra-ui/react'
import React from 'react'


type HomeLinkProps = {
  title: string,
  desc: string,
  url: string
};

function HomeLink(props: HomeLinkProps) {
  return (
    <Box
      flex='1'
      p={8}
      bg='gray.50'
      color='gray.600'
      borderWidth='1px'
      borderColor='gray.300'
      borderRadius={{ base: 0, md: 4 }}
    >
      <Flex
        h='100%'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Box>
          <Heading
            size={{ base: 'md', md: 'lg' }}
            mb={2}
          >{props.title}</Heading>
          <Text>{props.desc}</Text>
        </Box>
        <Box
          w='100%'
          textAlign='right'
        >
          <Link
            href={props.url}
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
            isExternal
          >
            <Button
              colorScheme='brand'
              size='md'
              mt={{ base: 4, md: 2 }}
            >
              Go <ArrowForwardIcon ml={2} />
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  )
}

export default HomeLink
